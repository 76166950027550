import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import "./Profile.css";
import "react-phone-number-input/style.css";
import CountryCodeSelector from "../../core/CountryCodeSelector/CountryCodeSelector";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import avatarMen from "../../assets/images/avatar-men.png";
import avatarWomen from "../../assets/images/avatar-women.png";
import daily_calendar from "../../assets/images/daily_calendar.png";
import week_calendar from "../../assets/images/week_calendar.png";
import month_calendar from "../../assets/images/month_calendar.png";
import { useProfileAccountActions } from "./profile.logic";
import CircularLoading from "../../shared/CircularLoading/CircularLoading";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { TabsType } from "./models/tabModels";

const Profile: React.FC = () => {
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState(0);
  const [activeAvatar, setActiveAvatar] = useState<"men" | "women">("men");
  const [activeNotification, setActiveNotification] = useState<
    "daily" | "week" | "month"
  >("daily");

  const { personalInformationPost, isLoading } = useProfileAccountActions();

  const handleTabClick = (index: number) => {
    setActiveTab(index);
  };

  const handleCheckboxChange = () => {
    setIsChecked((prev) => !prev);
  };

  const handleContinueClick = (values: InitialValuesType) => {   
    if (activeTab < tabs.length - 1) {
      setActiveTab(activeTab + 1);
    } else {
      personalInformationPost({
        name: values.nameValue,
        surname: values.lastNameValue,
        companyPosition: values.occupationValue,
        companyName: values.companyValue,
        country: values.country,
        countryCode: values.countryCode,
        mobileNumber: values.mobileNumber,
        avatar: activeAvatar,
        notificationEnabled: !isChecked,
        notifications: activeNotification,
      });
    }
  };

  const handleAvatarSelection = (avatar: "men" | "women") => {
    setActiveAvatar(avatar);
  };

  const handleNotificationSelection = (
    notification: "daily" | "week" | "month"
  ) => {
    setActiveNotification(notification);
  };

  const [isChecked, setIsChecked] = useState(false);

  const tabs: TabsType = [
    {
      title: t("register.testProfile.step_1.name"),
      contentTitle: t("register.testProfile.step_1.title"),
      contentRows: [
        [
          {
            placeholder: t("register.testProfile.step_1.name.placeholder"),
            name: "nameValue",
            component: null
          },
          {
            placeholder: t("register.testProfile.step_1.lastname.placeholder"),
            name: "lastNameValue",
            component: null
          },
        ],
        [
          {
            component: <CountryCodeSelector />,
            name: "countryCode",
          },
          {
            placeholder: t("register.testProfile.step_1.country.placeholder"),
            name: "country",
            component: null,
          },
        ],
        [
          {
            placeholder: t("register.testProfile.step_1.company.placeholder"),
            name: "companyValue",
            component: null,
          },
          {
            placeholder: t("register.testProfile.step_1.position.placeholder"),
            name: "occupationValue",
            component: null,
          },
        ],
      ],
    },
    // {
    //   title: t("register.testProfile.step_2.name"),
    //   contentTitle: t("register.testProfile.step_2.title"),
    //   contentRows: [
    //     [
    //       {
    //         placeholder: t("register.testProfile.step_2.avatar.placeholder"),
    //         name: "avatar",
    //       },
    //     ],
    //   ],
    // },
    // {
    //   title: t("register.testProfile.step_3.name"),
    //   contentTitle: t("register.testProfile.step_3.title"),
    //   contentRows: [
    //     [
    //       {
    //         placeholder: t(
    //           "register.testProfile.step_2.notification.placeholder"
    //         ),
    //         name: "notification",
    //       },
    //     ],
    //   ],
    // },
  ];
  type ExtractFieldNames<T extends TabsType> = {
    [K in T[number]["contentRows"][number][number]["name"]]: string;
  };
  type InitialValuesType = ExtractFieldNames<typeof tabs>;
  const initialValues: InitialValuesType = {
    nameValue: "",
    lastNameValue: "",
    countryCode: "+1",
    mobileNumber: "",
    country: "",
    companyValue: "",
    occupationValue: "",
  };
  
  const validationSchema = Yup.object({
    nameValue: Yup.string().required(t('validation.required')).min(3, t('validation.min', { count: 3 })),
    lastNameValue: Yup.string().required(t('validation.required')).min(3, t('validation.min', { count: 3 })),
    country: Yup.string().required(t('validation.required')),
    mobileNumber: Yup.string().required(t('validation.required')),
  });

  if (isLoading) {
    return <CircularLoading />;
  }

  return (
    <div className="container container-test-profile mt-5">
      <div className="row">
        <div className="tabs">
          {tabs.map((tab, index) => (
            <div
              key={index}
              className={`tab-test-profile ${
                activeTab === index ? "tab-active" : ""
              }`}
              onClick={() => handleTabClick(index)}
            >
              <div
                className={`circle ${activeTab === index ? "circle-active" : ""}`}
              >
                {index + 1}
              </div>
              <div
                className={`text-test-profile ${
                  activeTab === index ? "text-test-profile-active" : ""
                }`}
              >
                {tab.title}
              </div>
            </div>
          ))}
        </div>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={(values) => {
            handleContinueClick(values);
          }}
        >
        {() => (
        <Form>
        <div className="tab-container">
          <div className="titleRow">{tabs[activeTab].contentTitle}</div>
          {tabs[activeTab].contentRows.map((row, index) => (
            <div key={index} className="row text-profile__row">
              {row.map((input, inputIndex) => (
                <div
                  key={inputIndex}
                  className={
                    input.placeholder ===
                    t("register.testProfile.step_3.notification.placeholder")
                      ? "col-12 col-lg-10 mt-1"
                      : "col-12 col-lg-6 mt-1"
                  }
                >
                  {input.component ||
                    (input.placeholder === "Pais de residencia" ? (
                      <>
                        <Field
                          as="select"
                          className="dropdown-general"
                          name={input.name}
                        >
                          <option value="">
                            {t("register.testProfile.step_1.country.placeholder")}
                          </option>
                          <option value="AG">Antigua y Barbuda</option>
                          <option value="AR">Argentina</option>
                          <option value="AW">Aruba</option>
                          <option value="BS">Bahamas</option>
                          <option value="BB">Barbados</option>
                          <option value="BZ">Belice</option>
                          <option value="BO">Bolivia</option>
                          <option value="BQ">Bonaire (Países Bajos)</option>
                          <option value="BR">Brasil</option>
                          <option value="CA">Canadá</option>
                          <option value="CL">Chile</option>
                          <option value="CO">Colombia</option>
                          <option value="CR">Costa Rica</option>
                          <option value="CU">Cuba</option>
                          <option value="CW">Curazao (Países Bajos)</option>
                          <option value="DM">Dominica</option>
                          <option value="EC">Ecuador</option>
                          <option value="SV">El Salvador</option>
                          <option value="US">Estados Unidos</option>
                          <option value="GD">Granada</option>
                          <option value="GL">
                            Groenlandia (Reino de Dinamarca)
                          </option>
                          <option value="GT">Guatemala</option>
                          <option value="GF">Guayana Francesa</option>
                          <option value="GQ">Guinea Ecuatorial</option>
                          <option value="GY">Guyana</option>
                          <option value="HT">Haití</option>
                          <option value="HN">Honduras</option>
                          <option value="KY">Islas Caimán (Reino Unido)</option>
                          <option value="TC">
                            Islas Turcas y Caicos (Reino Unido)
                          </option>
                          <option value="VG">
                            Islas Vírgenes Británicas (Reino Unido)
                          </option>
                          <option value="JM">Jamaica</option>
                          <option value="MQ">Martinica</option>
                          <option value="MX">México</option>
                          <option value="NI">Nicaragua</option>
                          <option value="PA">Panamá</option>
                          <option value="PY">Paraguay</option>
                          <option value="PE">Perú</option>
                          <option value="PR">Puerto Rico</option>
                          <option value="DO">República Dominicana</option>
                          <option value="KN">San Cristóbal y Nieves</option>
                          <option value="VC">San Vicente y las Granadinas</option>
                          <option value="LC">Santa Lucía</option>
                          <option value="SR">Surinam</option>
                          <option value="TT">Trinidad y Tobago</option>
                          <option value="UY">Uruguay</option>
                          <option value="VE">Venezuela</option>
                        </Field>
                        <ErrorMessage name={input.name} component="div" className="error-message-validation" />
                      </>
                    ) : input.placeholder ===
                      t("register.testProfile.step_2.avatar.placeholder") ? (
                      <div className="avatar-img">
                        <img
                          src={avatarWomen}
                          alt={
                            activeAvatar === "women"
                              ? "Avatar Women"
                              : "Avatar Men"
                          }
                          className={`avatar-img-item ${
                            activeAvatar === "women"
                              ? "avatar-img-item-active"
                              : ""
                          }`}
                          onClick={() => handleAvatarSelection("women")}
                        />
                        <img
                          src={avatarMen}
                          alt={
                            activeAvatar === "men"
                              ? "Avatar Men"
                              : "Avatar Women"
                          }
                          className={`avatar-img-item ${
                            activeAvatar === "men"
                              ? "avatar-img-item-active"
                              : ""
                          }`}
                          onClick={() => handleAvatarSelection("men")}
                        />
                      </div>
                    ) : input.placeholder ===
                      t(
                        "register.testProfile.step_3.notification.placeholder"
                      ) ? (
                      <div>
                        <div className="rowPanel">
                          <div
                            className={`col-3 panel-notification ${
                              activeNotification === "daily"
                                ? "panel-notification-active"
                                : ""
                            }`}
                            onClick={() => handleNotificationSelection("daily")}
                          >
                            <img
                              alt="daily_calendar"
                              src={daily_calendar}
                              className="calendar-img"
                            />
                            <div
                              className={`${activeNotification === "daily" ? "txt-config-active" : "txt-config"}`}
                            >
                              {t("register.testProfile.step_3.daily")}
                            </div>
                          </div>
                          <div
                            className={`col-3 panel-notification ${
                              activeNotification === "week"
                                ? "panel-notification-active"
                                : "txt-config"
                            }`}
                            onClick={() => handleNotificationSelection("week")}
                          >
                            <img
                              alt="week_calendar-img"
                              src={week_calendar}
                              className="calendar-img"
                              onClick={() =>
                                handleNotificationSelection("week")
                              }
                            />
                            <div
                              className={`${activeNotification === "week" ? "txt-config-active" : "txt-config"}`}
                            >
                              {t("register.testProfile.step_3.week")}
                            </div>
                          </div>
                          <div
                            className={`col-3 panel-notification ${
                              activeNotification === "month"
                                ? "panel-notification-active"
                                : ""
                            }`}
                            onClick={() => handleNotificationSelection("month")}
                          >
                            <img
                              alt="month_calendar"
                              src={month_calendar}
                              className="calendar-img"
                              onClick={() =>
                                handleNotificationSelection("month")
                              }
                            />
                            <div
                              className={`${activeNotification === "month" ? "txt-config-active" : "txt-config"}`}
                            >
                              {t("register.testProfile.step_3.month")}
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <label className="custom-checkbox-label">
                            <input
                              name={input.name}
                              type="checkbox"
                              className="custom-checkbox-input"
                              checked={isChecked}
                              onChange={handleCheckboxChange}
                            />
                            <span className="custom-checkbox-indicator"></span>
                            <span className="checkbox-text">
                              {t("register.testProfile.step_3.checkbox")}
                            </span>
                          </label>
                        </div>
                      </div>
                    ) : (
                      <>
                      <Field
                        type="text"
                        name={input.name}
                        className="textbox-general"
                        placeholder={input.placeholder}
                      />
                        <ErrorMessage name={input.name} component="div" className="error-message-validation" />
                      </>
                    ))}
                </div>
              ))}
            </div>
          ))}
        </div>
        <div className="btn-footer">
          <div className="btn-test-next">
            <button className="btn-test-next-text" type="submit">
              {(activeTab === tabs.length - 1)  
                  ? t("register.testProfile.footer.save") : t("register.testProfile.footer.button")}
            </button>
            <FontAwesomeIcon icon={faArrowRight} color="black" />
          </div>
        </div>
        <button type="submit">Submit</button>
        </Form>
        )}
        </Formik>
      </div>
    </div>
  );
};

export default Profile;
